import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecurityPage = () => (
  <Layout>
    <Seo title="Security Overview | Clove" />
    <div className={"relative bg-white py-16"}>
      <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl text-content"}>
        <h1 className="heading-primary">Security Overview</h1>

        <p>
          <strong>Last updated: October 22, 2020</strong>
        </p>

        <h3>Built on Amazon Web Services</h3>
        <p>
          Our application is built fully on AWS. We benefit from their
          data center and system development practices. We leverage AWS best
          practices to ensure that our systems are secure from the outside-world.
        </p>

        <h3>We protect your data.</h3>
        <p>
          In addition to normal means of encrypting data at rest, we ensure your
          integration keys are encrypted using an additional encryption layer. We
          leverage AWS database backups to ensure we keep your data intact.
        </p>

        <h3>Your data are sent using HTTPS.</h3>
        <p>
          Whenever your data are in transit between you and us, everything is
          encrypted, and sent using HTTPS. Within our firewalled private
          networks, data may be transferred unencrypted.
        </p>

        <p>
          Any files which you upload to us are stored and are encrypted at rest.
          Our application databases are generally not encrypted at rest — the
          information you add to the applications is active in our databases and
          subject to the same protection and monitoring as the rest of our
          systems.
        </p>

        <h3>Regularly-updated infrastructure.</h3>
        <p>
          Our software infrastructure is updated regularly with the latest
          security patches. Our products run on AWS networking
          infrastructure, which enables firewall and application security.
        </p>

        <h3>We protect your billing information.</h3>
        <p>
          We use Stripe to process your billing information. Card information is
          transmitted, stored, and processed securely on Stripe's PCI-Compliant
          network.
        </p>

        <h3>Have a concern? Need to report an incident?</h3>
        <p>
          Have you noticed abuse, misuse, an exploit, or experienced an incident
          with your account? Please{" "}
          <a href="mailto:hello@clovecx.com">contact us</a> to submit a report.
        </p>

        <p>
          Adapted from the{" "}
          <a href="https://github.com/basecamp/policies">
            Basecamp open-source policies
          </a>{" "}
          / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default SecurityPage
